import OfficeInput from "../office/OfficeInput";
import {DateInput} from "react-admin";

const CT_TEXT_PLAIN = 'text/plain';
const CT_TEXT_CSV = 'text/csv';
const CT_ZIP = 'application/zip';
const CT_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const appointmentsFile = {
  allowedTypes: [CT_TEXT_CSV],
  inputs: <>
    <OfficeInput source="office_id" filter={{'gatekeeper#ehr@_is_null': false}} fullWidth />
  </>,
  validator: (values, errors) => {
    if (!values.office_id) {
      errors.office_id = 'Required';
    }
  }
}

const claimsFile = {
  allowedTypes: [CT_TEXT_CSV],
}

const humhedisFile = {
  allowedTypes: [CT_TEXT_PLAIN, CT_TEXT_CSV, CT_ZIP],
  inputs: <>
    <DateInput source='report_date' />
  </>,
  validator: (values, errors) => {
    if (!values.report_date) {
      errors.report_date = 'Required';
    }
  }
}

const careplusFile = {
  allowedTypes: [CT_TEXT_PLAIN, CT_TEXT_CSV, CT_ZIP, CT_XLSX],
}

const egnyteFile = {
  allowedTypes: [CT_ZIP],
}

const bcbsFile = {
  allowedTypes: [CT_TEXT_PLAIN, CT_TEXT_CSV, CT_ZIP],
}

const unitedFile = {
  allowedTypes: [CT_TEXT_PLAIN, CT_TEXT_CSV, CT_ZIP],
}


export const FileClases = {
  'APPOINTMENTS': appointmentsFile,
  'CLAIMS': claimsFile,
  'HUMHEDIS': humhedisFile,
  'CAREPLUS': careplusFile,
  'EGNYTE': egnyteFile,
  'BCBS': bcbsFile,
  'UNITED': unitedFile
}
