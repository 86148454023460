import {SelectInput, useDataProvider} from "react-admin";
import {useState} from "react";
import {Typography} from "@mui/material";


const BilledStatusInput = ({source = 'insurance_provider_id', label = 'Billed status', ...rest}) => {
  /**
   * Select Input to select one insurance provider
   *
   * @type {string}
   */
  const dataProvider = useDataProvider();
  const [choices, setChoices] = useState(null);

  if (choices === null){
    dataProvider.getList('billing_claim_billed_status', {filter: {}})
      .then((response) => {
        if (response.total === 0) {
          console.log('No choices for billed_claim_status')
        } else {
          setChoices(response.data)
        }})
      .catch((error) => {
        console.log('Error while getting billed_status choices', error);
      });
    return <Typography>Loading...</Typography>
  }

  return (
    <SelectInput
      optionText="description"
      label={label}
      source={source} choices={choices}
      {...rest}
    />
  )
}

export default BilledStatusInput;
