import { Menu, usePermissions } from 'react-admin';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {Typography} from "@mui/material";
import {VITE_SENTRY_ENVIRONMENT} from "./config";
import {capitalize} from "lodash/string";

const USER = 'user'
const SUPERVISOR = 'supervisor'
const PROV_PRACTITIONER = 'prov-practitioner'

export const CustomMenu = () => {
  const entries = [
    {entry: <Menu.ResourceItem key="patient" name="patient"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="reconciliation_report" name="reconciliation_report"/>,
       roles: [USER, SUPERVISOR, PROV_PRACTITIONER]},
   {entry: <Menu.ResourceItem key="reconciliation_report_query" name="reconciliation_report_query"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="claim" name="claim"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="provider_practitioner" name="provider_practitioner"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="data_file" name="data_file"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="appointment" name="appointment"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="audit" name="audit"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="billing" name="billing_claim"/>,
       roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key="visit" name="visit"/>,
      roles: [USER, SUPERVISOR]},
   {entry: <Menu.ResourceItem key="gap" name="gap"/>,
      roles: [USER, SUPERVISOR]},
   {entry: <Menu.ResourceItem key="gap_patient" name="gap_patient"/>,
      roles: [USER, SUPERVISOR]},
    {entry: <Menu.ResourceItem key='gatekeeper' name="gatekeeper"/>,
       roles: [SUPERVISOR]},
    /*{entry: <Menu.ResourceItem key='file' name="file"/>,
       roles: [SUPERVISOR]},*/
      {entry: <Menu.ResourceItem key='insurance_file' name="insurance_file"/>,
      roles: [SUPERVISOR]},
    {entry: <Menu.Item key='bi-reports' to="/reports/bi" primaryText="BI Reports" leftIcon={<SummarizeIcon />}/>,
       roles: [SUPERVISOR]},
    {entry: <Menu.Item key='billing-reports' to="/reports/billing" primaryText="Billing Reports" leftIcon={<SummarizeIcon />}/>,
       roles: [SUPERVISOR]},
  ];

  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  return (
  <Menu>
    <Typography
      variant="caption"
      color='grey'
      style={{
        marginLeft: '16px',
      }}
    >
      Realm: {capitalize(VITE_SENTRY_ENVIRONMENT)}
    </Typography>
    {entries.map(({entry, roles}) => roles.some(role => permissions.includes(role)) ? entry : null)}
  </Menu>
);}
